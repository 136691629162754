import UserLoggedService from "@/services/resources/UserLoggedService";
import router from "@/router/index";
import EmailVerificationService from "@/services/resources/EmailVerificationService";
import ClubService from "@/services/resources/ClubService";
import SubUserService from "@/services/resources/SubUsersService";

const serviceSubUser = SubUserService.build();
const service = UserLoggedService.build();

export default {
  userRequest: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      service
        .get("")
        .then((resp) => {
          commit("userSuccess", resp);
          //dispatch('redirectUserToHome')
          window.localStorage.id_user_greenn = resp.id;
          resolve(resp);
        })
        .catch((err) => {
          commit("userError");
          reject(err);
          // if resp is unauthorized, logout, to
          // dispatch('authLogout')
        });
    });
  },
  getPanels: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      serviceSubUser
        .get("/panels")
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          commit("userError");
          reject(err);
        });
    });
  },
  setPanel: (context, payload) => {
    context.commit("setPanel", payload);
  },
  resetPanel: (context, payload) => {
    context.commit("resetPanel");
  },
  emailVerification: ({ commit, dispatch }) => {
    const serviceEmail = EmailVerificationService.build();
    return new Promise((resolve, reject) => {
      serviceEmail
        .get("/check-user")
        .then((resp) => {
          if (resp.verified === false) {
            dispatch("redirectUserVerification");
          }
          commit("verifyEmail", resp.verified);
          resolve(resp.verified);
        })
        .catch((err) => {
          commit("userError");
          reject(err);
        });
    });
  },
  clubVerification: ({ commit, dispatch }) => {
    const serviceClub = ClubService.build();
    return new Promise((resolve, reject) => {
      serviceClub
        .get("/check-has-integration")
        .then((resp) => {
          commit("userClub", resp);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  clubLogin: ({ commit, dispatch }) => {
    const serviceClub = ClubService.build();
    return new Promise((resolve, reject) => {
      serviceClub
        .get("/login")
        .then((resp) => {
          let routeData =
            process.env.VUE_CLUB_API_HOST + "/?rdr=" + resp.string;
          window.open(routeData, "_blank");
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  adminUserRequest: ({ commit, dispatch }) => {
    service
      .get("")
      .then((resp) => {
        // commit('adminUserSuccess', resp);
        commit("userSuccess", resp);
        dispatch("redirectUserToHome");
      })
      .catch((err) => {
        commit("userError");
        // if resp is unauthorized, logout, to
        // dispatch('authLogout')
      });
  },
  redirectUserToHome: (context) => {
    router.push("/dashboard");
  },
  redirectUserVerification: (context) => {
    if (router.history.current.path != "/verificar-email") {
      router.push(`/verificar-email${window.location.search}`).catch(() => {});
    }
  },
};
