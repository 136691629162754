export default{
    getProfile: state => state.user,
    verifyEmail: state => state.verifyEmail,
    setPanel: state => state.setPanel,
    userClub: state => state.userClub,
    isProfileLoaded: state => !!state.user.name,
    isSeller: (state) => {
        
        if(!state.user)
            return false;
            
        let roles =  state.user.roles;

        if(!roles){
            return false;
        }

        var isSeller = false;
        roles.forEach(function(role, index){
            if(role.name === 'Seller'){
                isSeller = true;
               return;
           }
       });
        return isSeller;
    },
    isClient: (state) => {

        let roles =  state.user.roles;

        if(!roles){
            return false;
        }

        var isClient = false;
        roles.forEach(function(role, index){
            if(role.name === 'Client'){
                isClient = true;
               return;
           }
       });
        return isClient;
    }
}
