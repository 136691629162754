import axios from 'axios';
import Cookies from 'js-cookie';
import store from '@/store/index'
import router from '@/router/index'


import {
  EventBus
} from '@/main.js'

const standard = axios.create({
  baseURL: process.env.VUE_APP_API_HOST,
  timeout: 100000,
  transformResponse: [
    function (data) {
      return data;
    },
  ],
});

standard.interceptors.response.use(function (response) {
  return response;
}, function (err) {
  if (err.response) {
    switch (err.response.status) {
      case 422: {
        if (err.response.request.responseType == 'blob') {
          EventBus.$emit('errorNotify', 'Erro ao solicitar o download deste arquivo')
        } else {
          let erros = err.response.data;
          if (typeof erros == 'string') {
            erros = JSON.parse(erros)
          }
          for (let erro in erros) {
            EventBus.$emit('errorNotify', erros[erro][0])
          }
        }
        break;
      }
      case 400: {
        EventBus.$emit('errorNotify', '400 - Algum parâmetro obrigatório não foi enviado ou é inválido.')
        break;
      }
      case 401: {
        store.dispatch("logoutRequest").catch(function (err) {});
        router.push('/')
        EventBus.$emit('errorNotify', '401 - Usuário não autorizado. Refaça o login e tente novamente')
        break;
      }
      case 404: {
        EventBus.$emit('errorNotify', '404 - A URL configurada na requisição não existe.')
        break;
      }
      case 405: {
        EventBus.$emit('errorNotify', '405 - Método não permitido.')
        break;
      }
      case 408: {
        EventBus.$emit('errorNotify', '408 - Aplicação não retornou uma resposta dentro do tempo esperado.')
        break;
      }
      case 500: {
        EventBus.$emit('errorNotify', '500 - A requisição foi enviada, porém ele retornou algum erro interno')
        break;
      }
      default: {
        EventBus.$emit('errorNotify', 'Erro ao executar a requisição')
        break;
      }
    }
  } else {
    EventBus.$emit('errorNotify', 'Erro ao executar a requisição')
  }

  return Promise.reject(err);
});


if (Cookies.get('access_token')) {
  standard.defaults.headers.common['Authorization'] = Cookies.get('access_token');
}
standard.defaults.headers.common['Access-Control-Allow-Origin'] = '*';



export default standard;