import store from '@/store/index'

function isAuthenticated(to, from, next) {
  if (store.getters.isAuthenticated && store.getters.verifyEmail === false && to.name !== 'VerificaEmail') {
    if (to.name === 'VerificaEmailHash') {
      return next()+window.location.search
    } else {
      if (to.path != from.path)
        return next(`/verificar-email${window.location.search}`)
    }
  }

  if (store.getters.isAuthenticated) {
    return next()
  }

  return next(`/${window.location.search}`)
}
const authentication = isAuthenticated

export default authentication